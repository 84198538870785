// 技术成熟度
export const stageOptions = [{
        label: '研发阶段',
        value: 'RESEARCH_AND_DEVELOPMENT'
    },
    {
        label: '样品阶段',
        value: 'SAMPLE'
    },
    {
        label: '小试阶段',
        value: 'SMALL_TEST'
    },
    {
        label: '中试阶段',
        value: 'PILOT_TEST'
    },
    {
        label: '量产阶段',
        value: 'MASS_PRODUCTION'
    }
];
export const tradingMethodOptions = [{
        label: '转让',
        value: 'TRANSFER'
    },
    {
        label: '普通许可',
        value: 'GENERAL_PERMIT'
    },
    {
        label: '独占许可',
        value: 'EXCLUSIVE_PERMIT'
    },
    {
        label: '排他许可',
        value: 'EXCLUSIVITY_PERMIT'
    },
    {
        label: '入股',
        value: 'INVEST'
    }
];
export const lawStatusOptions1 = [{
        label: '有权—授权',
        value: 'EMPOWERED_DELEGATED'
    },
    {
        label: '无权—未缴年费',
        value: 'UNPAID_ANNUAL_FEE'
    },
    {
        label: '无权—期限届满',
        value: 'EXPIRY_OF_TERM'
    }
];
export const tradingMethodOptionTitle = [{
        label: '转让',
        value: 'TRANSFER'
    },
    {
        label: '许可',
        value: 'GENERAL_PERMIT'
    },
    // {
    //     label: '独占许可',
    //     value: 'EXCLUSIVE_PERMIT'
    // },
    // {
    //     label: '排他许可',
    //     value: 'EXCLUSIVITY_PERMIT'
    // },
    // {
    //     label: '入股',
    //     value: 'INVEST'
    // },
    {
        label: '其他',
        value: 'OTHER'
    }
];
export const tradingMethodOptionPatent = [{
        label: '转让',
        value: 'TRANSFER'
    },
    {
        label: '普通许可',
        value: 'GENERAL_PERMIT'
    },
    {
        label: '独占许可',
        value: 'EXCLUSIVE_PERMIT'
    },
    {
        label: '排他许可',
        value: 'EXCLUSIVITY_PERMIT'
    },
    {
        label: '入股',
        value: 'INVEST'
    }
];
export const tradingMethodOption = [{
        label: '转让',
        value: 'TRANSFER'
    },
    {
        label: '普通许可',
        value: 'GENERAL_PERMIT'
    },
    {
        label: '独占许可',
        value: 'EXCLUSIVE_PERMIT'
    },
    {
        label: '排他许可',
        value: 'EXCLUSIVITY_PERMIT'
    },
    {
        label: '入股',
        value: 'INVEST'
    },
    {
        label: '其他',
        value: 'OTHER'
    }
];
export const commissionTypeOptions = [{
        label: '佣金',
        value: 'COMMISSION'
    },
    {
        label: '百分比',
        value: 'PERCENTAGE'
    }
];

export const tpTypeOptions = [{
        label: '技术',
        value: 'TECHNOLOGY'
    },
    {
        label: '产品',
        value: 'PRODUCT'
    }
];

// 价格区间
export const moneyOptions = [{
        label: '1万以内',
        value: '0,1'
    },
    {
        label: '1-5万',
        value: '1,5'
    },
    {
        label: '5-10万',
        value: '5,10'
    },
    {
        label: '10-20万',
        value: '10,20'
    },
    {
        label: '20-50万',
        value: '20,50'
    },
    {
        label: '50-100万',
        value: '50,100'
    },
    {
        label: '100万以上',
        value: '100'
    },
    {
        label: '面议',
        value: true
    }
];

export const loanTermOptions = [{
        label: '3个月以下',
        value: 'UNDER_THREE_MONTHS'
    },
    {
        label: '3-6个月',
        value: 'THREE_SIX_MONTHS'
    },
    {
        label: '6个月-1年',
        value: 'SIX_MONTHS_ONE_YEAR'
    },
    {
        label: '1-2年',
        value: 'ONE_TWO_YEARS'
    }
];

export const logoTypeOptions = [{
        label: '普通',
        value: 'GENERAL'
    },
    {
        label: '集体',
        value: 'COLLECTIVE'
    },
    {
        label: '证明',
        value: 'PROOF'
    }
];

//24
export var patentTypeOptions = [];
export var patentTypeOptionsOne = [{
        label: '发明',
        value: 'INVENTION'
    },
    {
        label: '实用新型',
        value: 'UTILITY_MODEL'
    },
    {
        label: '外观设计',
        value: 'APPEARANCE_DESIGN'
    }
];
export const lawStatusOptions = [{
        label: '有权—授权',
        value: 'EMPOWERED_DELEGATED'
    },
    {
        label: '无权—未缴年费',
        value: 'UNPAID_ANNUAL_FEE'
    },
    {
        label: '无权—期限届满',
        value: 'EXPIRY_OF_TERM'
    },
    {
        label: '无权—驳回',
        value: 'REJECTED'
    },
    {
        label: '无权—视为撤回',
        value: 'DEEMED_WITHDRAWN'
    },
    {
        label: '审中—发明公开',
        value: 'DISCLOSURE_OF_INVENTIONS'
    },
    {
        label: '审中—实质审查',
        value: 'SUBSTANTIVE_REVIEW'
    }
];
export const lawStatusOption = [{
        label: '有权—申请',
        value: 'APPLY'
    },
    {
        label: '有权—授权',
        value: 'EMPOWERED_DELEGATED'
    },
    {
        label: '无权—未缴年费',
        value: 'UNPAID_ANNUAL_FEE'
    },
    {
        label: '无权—期限届满',
        value: 'EXPIRY_OF_TERM'
    }
];

export const purchaseTypeOptions = [{
        label: '公开招标',
        value: 'PUBLIC_TENDER'
    },
    {
        label: '邀请招标',
        value: 'INVITATION_TO_TENDER'
    },
    {
        label: '竞争性谈判',
        value: 'COMPETITIVE_NEGOTIATION'
    },
    {
        label: '询价',
        value: 'INQUIRY'
    }
];

export const purchaseItemOptions = [{
        label: '工程',
        value: 'ENGINEERING',
        path: '/purchase'
    },
    {
        label: '货物',
        value: 'CARGO',
        path: '/goods'
    },
    {
        label: '服务',
        value: 'SERVICE',
        path: '/service'
    }
];

export const typeOptions = [{
        label: '脱密国防专利',
        value: 'DECLASSIFICATION'
    },
    {
        label: '军转民专利',
        value: 'MILITARY_TO_CIVILIAN'
    }
];

export const ownerTypeOptions = [{
        label: '高校',
        value: 'UNIVERSITIES_AND_INSTITUTES'
    },
    {
        label: '企业',
        value: 'ENTERPRISES_AND_INSTITUTIONS'
    },

    {
        label: '研究院',
        value: 'RESEARCH'
    }
];

//专利状态
export const patentStatusOptions = [{
        label: '申请中',
        value: 'APPLY'
    },
    {
        label: '已授权未期满',
        value: 'AUTHORISED'
    }
];
export const modeOptions = [{
        label: '转让',
        value: 'TRANSFER'
    },
    {
        label: '普通许可',
        value: 'GENERAL_PERMIT'
    },
    {
        label: '独占许可',
        value: 'EXCLUSIVE_PERMIT'
    },
    {
        label: '排他许可',
        value: 'EXCLUSIVITY_PERMIT'
    },
    {
        label: '入股',
        value: 'INVEST'
    }
];

//需求侧版权软著服务转移转化
export const mode2Options = [{
        label: '转让',
        value: 'TRANSFER'
    },
    {
        label: '许可',
        value: 'PERMIT'
    },
    {
        label: '其他',
        value: 'OTHER'
    }
];
export const mode3Options = [{
        label: '转让',
        value: 'TRANSFER'
    },
    {
        label: '许可',
        value: 'PERMIT'
    },
    {
        label: '入股',
        value: 'INVEST'
    },
    {
        label: '其他',
        value: 'OTHER'
    }
];
export const affiliationOptions = [{
        label: '知识产权',
        value: 'INTELLECTUAL_PROPERTY'
    },
    {
        label: '国防科工',
        value: 'NATIONAL_DEFENCE'
    }
];
export const tradingMethodOptionsDemands = [{
        label: '转让',
        value: 'TRANSFER'
    },
    {
        label: '许可',
        value: 'PERMIT'
    },
    {
        label: '作价入股',
        value: 'INVEST'
    },
    {
        label: '合作生产',
        value: 'CO_PRODUCTION'
    },
    {
        label: '其他',
        value: 'OTHER'
    }
];
export const tradingMethodOptionsDemand = [{
        label: '技术转让',
        value: 'TRANSFER'
    },
    {
        label: '技术许可',
        value: 'PERMIT'
    },
    {
        label: '技术入股',
        value: 'INVEST'
    },
    {
        label: '合作生产',
        value: 'CO_PRODUCTION'
    },
    {
        label: '其他',
        value: 'OTHER'
    }
];

export const digitalTypeOptions = [{
        label: '技术',
        value: 'TECHNOLOGY'
    },
    {
        label: '产品',
        value: 'PRODUCT'
    },
    {
        label: '企业',
        value: 'COMPANY'
    }
];
export const intePropLawStatus = [{
        label: '有效',
        value: 'EFFECTIVE'
    },
    {
        label: '失效',
        value: 'FAILURE'
    },
    {
        label: '未知',
        value: 'UNKNOWN'
    }
];

export const phonePattern = /^[1][3,4,5,7,8,9][0-9]{9}$/;

export const navTypeOptions = [{
        label: '首页',
        value: 'TOTAL'
    },
    {
        label: '政策法律',
        value: 'POLICY'
    },
    {
        label: '市场运营',
        value: 'MARKET'
    },
    {
        label: '实物代理',
        value: 'AGENT'
    },
    {
        label: '融合数据',
        value: 'FUSION_DATA'
    }
];

export const copyrightType = [{
        label: '版权',
        value: 'COPY'
    },
    {
        label: '软著',
        value: 'SOFT'
    }
];

export const categoryOptions = [{
        label: '政策',
        value: 'POLICY'
    },
    {
        label: '法律',
        value: 'LAW'
    },
    {
        label: '新闻',
        value: 'NEWS'
    }
];

export const caseTypeOptions = [{
        label: '脱密专利',
        value: 'DECLASSIFICATION'
    },
    {
        label: '专利',
        value: 'GENERAL'
    }
];

export const caseType2Options = [{
        label: '脱密专利',
        value: 'DECLASSIFICATION'
    },
    {
        label: '专利',
        value: 'GENERAL'
    },
    {
        label: '商标',
        value: 'LOGO',
        path: '/successCaseslogo'
    },
    {
        label: '版权',
        value: 'COPYRIGHT',
        path: '/successCopyright'
    },
    {
        label: '软著',
        value: 'SOFT_COPYRIGHT',
        path: '/successCopyright'
    },
    {
        label: '技术',
        value: 'TECHNOLOGY',
        path: '/successProduct'
    },
    {
        label: '产品',
        value: 'PRODUCT',
        path: '/successProduct'
    },
    {
        label: '服务',
        value: 'SERVICE',
        path: '/successProduct'
    }
];